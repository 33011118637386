@charset "UTF-8";
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/hurme-geometric.otf");
}
@font-face {
  font-family: RobotoMedium;
  src: url("/assets/fonts/gotham-narrow-medium.otf");
}
@font-face {
  font-family: RobotoBook;
  src: url("/assets/fonts/gotham-narrow-book.otf");
}
@font-face {
  font-family: RobotoBold;
  src: url("/assets/fonts/gotham-narrow-bold.otf");
}
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: RobotoBold;
  src: url("/assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: RobotoMedium;
  src: url("/assets/fonts/Roboto-Medium.ttf");
}
.i-face {
  display: inline-block;
  background: url("/assets/img/face.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

.i-money {
  display: inline-block;
  background: url("/assets/img/money.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

.i-person {
  display: inline-block;
  background: url("/assets/img/person.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

.i-refresh {
  display: inline-block;
  background: url("/assets/img/refresh.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/hurme-geometric.otf");
}
@font-face {
  font-family: RobotoMedium;
  src: url("/assets/fonts/gotham-narrow-medium.otf");
}
@font-face {
  font-family: RobotoBook;
  src: url("/assets/fonts/gotham-narrow-book.otf");
}
@font-face {
  font-family: RobotoBold;
  src: url("/assets/fonts/gotham-narrow-bold.otf");
}
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: RobotoBold;
  src: url("/assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: RobotoMedium;
  src: url("/assets/fonts/Roboto-Medium.ttf");
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

body {
  scrollbar-face-color: #d9d9d9;
  scrollbar-track-color: rgba(0, 0, 0, 0);
}

body {
  font-family: Roboto !important;
}

button.close {
  padding-bottom: 2px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  top: -3px;
  position: relative;
}

.maintenance-alert {
  color: #ad2121;
  background-color: #FDF1BA;
  padding: 16px;
  margin: 0 12px;
}

.app-content {
  padding: 10px 0 40px;
}

a {
  cursor: pointer;
}

.app-main {
  padding-inline: 350px 50px;
  padding-top: 80px;
  transition: 0.2s;
}
.app-main h1 {
  color: #455A64;
  font-weight: bold;
  font-size: 22px;
  padding: 4px 0;
  font-family: RobotoBold;
}

.app-main.menuCollapsed {
  padding-inline: 100px 44px;
}

.app-main.noMenu {
  padding-left: 0 !important;
}

ng2-completer {
  padding: 0 !important;
  padding-left: 15px !important;
}

ng2-completer .completer-holder > input {
  background: 0;
  width: 100%;
  border: 0;
  height: 35px;
  color: #464a4c;
}

ng2-completer .completer-holder > input:focus {
  outline: none !important;
}

@media all and (max-width: 768px) {
  .email-messages .email-not-confirmed-message {
    z-index: 10;
    padding-left: 60px;
  }

  .app-main, .app-main.menuCollapsed {
    padding-inline: 16px;
  }

  .aviso-cadastro {
    padding: 5px 80px !important;
    font-size: 13px;
  }
}
.aviso-cadastro {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  padding: 5px;
  letter-spacing: 0.2px;
}

select {
  font-family: Roboto !important;
}

.completer-dropdown {
  overflow: scroll;
  height: 300px;
}

.email-confirmed-message {
  padding: 5px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.email-not-confirmed-message {
  padding: 5px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  left: 0;
}

.email-messages {
  position: relative;
}

contato > form > .row {
  transition: 0.2s;
}

conta > form > .row {
  transition: 0.2s;
}

.separator:hover + contato > form > .row {
  background: #f7f7f7;
}

.separator:hover + conta > form > .row {
  background: #f7f7f7;
}

body input:-webkit-autofill, body textarea:-webkit-autofill, body select:-webkit-autofill {
  -webkit-text-fill-color: #003F72 !important;
}

.auth-block.register .mat-form-field-infix, .auth-block.login .mat-form-field-infix {
  padding-bottom: 0;
}
.auth-block.register .mat-form-field-label, .auth-block.register .mat-form-field-invalid .mat-form-field-label, .auth-block.login .mat-form-field-label, .auth-block.login .mat-form-field-invalid .mat-form-field-label {
  color: #455A64;
  padding-left: 10px;
}
.auth-block.register .mat-form-field-underline, .auth-block.register .mat-form-field-invalid .mat-form-field-ripple, .auth-block.login .mat-form-field-underline, .auth-block.login .mat-form-field-invalid .mat-form-field-ripple {
  background-color: #003F72;
  z-index: 3;
}
.auth-block.register .mat-form-field-label-wrapper, .auth-block.login .mat-form-field-label-wrapper {
  font-size: 20px;
}
.auth-block.register .mat-form-field-label, .auth-block.login .mat-form-field-label {
  color: #455A64;
  font-size: 16px;
}
.auth-block.register .input-group .form-control, .auth-block.login .input-group .form-control {
  width: 100%;
  padding-left: 7px;
  caret-color: #003F72;
  padding-top: 5px;
  margin-top: 5px;
}
.auth-block.register .mat-form-field-infix, .auth-block.login .mat-form-field-infix {
  border: 0;
}
.auth-block.register .input-group, .auth-block.login .input-group {
  margin-bottom: 20px;
}
.auth-block.register .mat-form-field-wrapper, .auth-block.login .mat-form-field-wrapper {
  padding-bottom: 0;
}
.auth-block.register .mat-form-field-underline, .auth-block.login .mat-form-field-underline {
  bottom: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  conta > form > .row {
    display: block;
  }
}
#app-menu .inactive .svg-container polygon, #app-menu .inactive .svg-container g, #app-menu .inactive .svg-container path, #app-menu .inactive .svg-container rect {
  fill: #99C1EC;
}
#app-menu .inactive:hover .svg-container polygon, #app-menu .inactive:hover .svg-container g, #app-menu .inactive:hover .svg-container path, #app-menu .inactive:hover .svg-container rect {
  fill: auto !important;
}

.orange-alert {
  background: #0063CF;
  text-align: center;
  padding: 8px 0;
  font-size: 20px;
}

.lightblue-alert {
  background: #dde4e9;
  text-align: center;
  padding: 8px 0;
  font-size: 20px;
}

.center-me {
  margin: 0 auto;
}

.inline-block-center {
  text-align: center;
}

.table {
  margin-bottom: 0;
}
.table > thead > tr > th {
  border-bottom: 1px solid gainsboro;
  white-space: nowrap;
}
.table > thead > tr > th:last-child {
  padding-right: 16px;
}
.table > tbody > tr > tr:first-child {
  padding-top: 1px;
}
.table > tbody > tr > td {
  padding: 0 8px;
  line-height: 35px;
  border-top: 1px solid gainsboro;
}
.table > tbody > tr > td:last-child {
  padding-right: 16px !important;
}

.table-id {
  text-align: left !important;
  width: 40px;
}

.table-arr {
  width: 5px;
  padding: 10px 8px 8px 0 !important;
}

.table-no-borders {
  border: none;
}
.table-no-borders td, .table-no-borders th, .table-no-borders tr {
  border: none !important;
}

.editable-wrap .btn-group.form-control {
  background-color: transparent;
}

.editable-tr-wrap .editable-wrap {
  vertical-align: super;
}
.editable-tr-wrap .editable-controls input.editable-input {
  width: 110px;
}
.editable-tr-wrap td {
  width: 20%;
}

.editable-table-button {
  width: 70px;
}

.add-row-editable-table {
  margin-bottom: 10px;
}

.add-row-editable-table + table {
  margin-bottom: 5px;
}

.select-page-size-wrap {
  width: 150px;
}

.table .header-row th {
  vertical-align: middle;
  padding: 0 8px;
}

tr.editable-row input.form-control {
  vertical-align: middle;
}

.select-td .editable-select {
  margin-bottom: 1px;
}

@media screen and (max-width: 1199px) {
  .editable-tr-wrap .editable-wrap {
    vertical-align: middle;
  }
}
.browser-icons {
  width: 41px;
}

.st-sort-ascent, .st-sort-descent {
  position: relative;
}

.st-sort-ascent:after, .st-sort-descent:after {
  width: 0;
  height: 0;
  border-bottom: 4px solid #666666;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-bottom: 2px;
}

.st-sort-descent:after {
  transform: rotate(-180deg);
  margin-bottom: -2px;
}

.sortable th {
  cursor: pointer;
}
.sortable th:after {
  content: "";
  display: inline-block;
  width: 8px;
  margin-left: 8px;
}

a.email-link {
  color: #666666;
}
a.email-link:hover {
  color: #e85656;
}

input.search-input {
  margin-left: -8px;
  padding-left: 8px;
}

.table {
  margin: 4px 0 -12px 0;
}
.table a {
  cursor: pointer;
}

.vertical-scroll {
  max-height: 214px;
}

.status-button {
  width: 60px;
}

.table .editable-wrap .editable-controls, .table .editable-wrap .editable-error {
  vertical-align: sub;
}
.table .editable-wrap .editable-controls .btn, .table .editable-wrap .editable-error .btn {
  padding: 3px 8px;
}
.table .editable-wrap .editable-controls .btn.dropdown-toggle, .table .editable-wrap .editable-error .btn.dropdown-toggle {
  padding: 3px 20px;
  margin-top: 3px;
}
.table .editable-wrap .editable-controls input, .table .editable-wrap .editable-error input {
  line-height: 1px;
  height: 30px;
}

.form-inline button[type=submit].editable-table-button {
  margin-left: 0;
}

.table > thead > tr > th {
  border-bottom: none;
}
.table > tbody > tr.no-top-border:first-child > td {
  border-top: none;
}

.black-muted-bg {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid gainsboro;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.table > tbody > tr.primary > td {
  background-color: rgba(32, 158, 145, 0.7);
  color: #ffffff;
  border: none;
}
.table > tbody > tr.primary > td a.email-link {
  color: #ffffff;
}
.table > tbody > tr.primary > td a.email-link:hover {
  color: #e85656;
}

.table > tbody > tr.success > td {
  background-color: rgba(144, 185, 0, 0.7);
  color: #ffffff;
  border: none;
}
.table > tbody > tr.success > td a.email-link {
  color: #ffffff;
}
.table > tbody > tr.success > td a.email-link:hover {
  color: #e85656;
}

.table > tbody > tr.warning > td {
  background-color: rgba(223, 184, 28, 0.7);
  color: #ffffff;
  border: none;
}
.table > tbody > tr.warning > td a.email-link {
  color: #ffffff;
}
.table > tbody > tr.warning > td a.email-link:hover {
  color: #e85656;
}

.table > tbody > tr.danger > td {
  background-color: rgba(232, 86, 86, 0.7);
  color: #ffffff;
  border: none;
}
.table > tbody > tr.danger > td a.email-link {
  color: #ffffff;
}
.table > tbody > tr.danger > td a.email-link:hover {
  color: #e85656;
}

.table > tbody > tr.info > td {
  background-color: rgba(45, 172, 209, 0.7);
  color: #ffffff;
  border: none;
}
.table > tbody > tr.info > td a.email-link {
  color: #ffffff;
}
.table > tbody > tr.info > td a.email-link:hover {
  color: #e85656;
}

.editable-click, a.editable-click {
  color: #666666;
  border-bottom: dashed 1px #d6d6d6;
}

.editable-empty {
  color: #c54949;
}

.table > tbody > tr > th {
  border: none;
}

.table-striped > tbody > tr > td {
  border: none;
}

.page-item.disabled .page-link, .page-item.disabled .page-link:focus, .page-item.disabled .page-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #666666;
}

.editable-buttons .btn-with-icon i {
  margin-right: 0;
}

.table-responsive {
  margin-top: 10px;
}

.table thead th {
  vertical-align: middle !important;
}

tr {
  vertical-align: middle !important;
}

.label {
  border-radius: 0;
}

.label-primary {
  background: #209e91;
}

.label-info {
  background: #63bbb2;
}

.label-success {
  background: #90b900;
}

.label-warning {
  background: #dfb81c;
}

.label-danger {
  background: #e85656;
}

.form-horizontal label {
  line-height: 34px;
  margin-bottom: 0;
  padding-top: 0 !important;
}

.form-group label {
  margin-bottom: 5px;
  color: #666666;
  font-weight: 400;
  font-size: 13px;
}

.form-control {
  color: #666666;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: none;
  font-size: 14px;
  height: 35px !important;
}
.form-control::-webkit-input-placeholder {
  color: #666666;
  opacity: 0.7;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  opacity: 0.7;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  opacity: 0.7;
}
.form-control:-ms-input-placeholder {
  color: #666666;
  opacity: 0.7;
}
.form-control:focus {
  color: #666666;
  box-shadow: none;
  border-color: #4db1a7;
  background: #ffffff;
}

select.form-control {
  padding-left: 8px;
}

select.form-control:not([multiple]) option {
  color: #7d7d7d;
}

select.form-control[multiple] option {
  color: #666666;
}

textarea.form-control {
  height: 96px;
}

.form-inline .form-group input {
  width: 100%;
}
.form-inline .form-group label {
  margin-right: 12px;
}
.form-inline button[type=submit] {
  margin-left: 12px;
}

.switch-container {
  display: inline-block;
}
.switch-container.primary .bootstrap-switch.bootstrap-switch-on {
  border-color: #209e91;
}
.switch-container.success .bootstrap-switch.bootstrap-switch-on {
  border-color: #90b900;
}
.switch-container.warning .bootstrap-switch.bootstrap-switch-on {
  border-color: #dfb81c;
}
.switch-container.danger .bootstrap-switch.bootstrap-switch-on {
  border-color: #e85656;
}
.switch-container.info .bootstrap-switch.bootstrap-switch-on {
  border-color: #63bbb2;
}

.bootstrap-switch {
  border-radius: 5px;
  border: 1px solid #ffffff;
  transition: border-color ease-in-out 0.7s, box-shadow ease-in-out 0.7s;
}
.bootstrap-switch:focus {
  outline: none;
}
.bootstrap-switch.bootstrap-switch-off {
  border-color: #d6d6d6;
}
.bootstrap-switch.bootstrap-switch-focused {
  box-shadow: none;
}
.bootstrap-switch.bootstrap-switch-focused.bootstrap-switch-off {
  border-color: #d6d6d6;
}
.bootstrap-switch .bootstrap-switch-container {
  border-radius: 0;
}
.bootstrap-switch .bootstrap-switch-container:focus {
  outline: none;
}
.bootstrap-switch .bootstrap-switch-handle-on {
  border-radius: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #ffffff;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  background: #90b900;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background: #209e91;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  background: #dfb81c;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  background: #e85656;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  background: #63bbb2;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  border-radius: 0;
}
.bootstrap-switch .bootstrap-switch-label {
  background: transparent;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.2s;
}

.switches {
  margin-left: -12px;
  margin-bottom: -12px;
}
.switches .switch-container {
  float: left;
  margin-left: 12px;
  margin-bottom: 12px;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}
.input-group > span {
  border-radius: 0;
}

.nowrap {
  white-space: nowrap;
}

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

label.custom-radio {
  @padding-right : 0;
  padding-left: 0;
  margin-bottom: 0;
}
label.custom-radio > input {
  height: 0;
  z-index: -100 !important;
  opacity: 0;
  position: absolute;
}
label.custom-radio > input:checked + span:before {
  content: "\f00c";
  font-weight: 300;
}
label.custom-radio > input:disabled + span {
  color: #dddddd;
  cursor: not-allowed;
}
label.custom-radio > input:disabled + span:before {
  border-color: #dddddd !important;
  cursor: not-allowed;
}
label.custom-radio > span {
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: 16px;
  font-weight: 300;
  cursor: pointer;
  padding-left: 22px;
  width: 100%;
}
label.custom-radio > span:before {
  cursor: pointer;
  font-family: fontAwesome;
  font-weight: 300;
  font-size: 12px;
  color: #666666;
  content: " ";
  background-color: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  height: 16px;
  line-height: 14px;
  min-width: 16px;
  margin-right: 6px;
  position: relative;
  top: 0;
  margin-left: -22px;
  float: left;
}
label.custom-radio > span:hover:before {
  border-color: #4db1a7;
}
label.custom-radio > input:checked + span:before {
  content: "\f111";
}
label.custom-radio > span:before {
  border-radius: 16px;
  font-size: 9px;
}

label.custom-input-primary > span:before {
  color: #209e91;
}
label.custom-input-primary > span:hover:before {
  border-color: #209e91;
}

label.custom-input-success > span:before {
  color: #90b900;
}
label.custom-input-success > span:hover:before {
  border-color: #90b900;
}

label.custom-input-warning > span:before {
  color: #dfb81c;
}
label.custom-input-warning > span:hover:before {
  border-color: #dfb81c;
}

label.custom-input-danger > span:before {
  color: #e85656;
}
label.custom-input-danger > span:hover:before {
  border-color: #e85656;
}

.form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 0;
}

.input-demo {
  line-height: 25px;
}
.input-demo ba-multi-checkbox {
  width: 100%;
}

.input-group-addon {
  line-height: inherit;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}
.has-feedback label ~ .form-control-feedback {
  top: 19px;
  font-size: 18px;
}

.bootstrap-select .btn-default:focus {
  color: #666666;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #ffffff;
  color: #dddddd;
  border-color: #e2e2e2;
}
.form-control[disabled]::-webkit-input-placeholder, .form-control[readonly]::-webkit-input-placeholder, fieldset[disabled] .form-control::-webkit-input-placeholder {
  color: #666666;
  opacity: 0.5;
}
.form-control[disabled]:-moz-placeholder, .form-control[readonly]:-moz-placeholder, fieldset[disabled] .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  opacity: 0.5;
}
.form-control[disabled]::-moz-placeholder, .form-control[readonly]::-moz-placeholder, fieldset[disabled] .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  opacity: 0.5;
}
.form-control[disabled]:-ms-input-placeholder, .form-control[readonly]:-ms-input-placeholder, fieldset[disabled] .form-control:-ms-input-placeholder {
  color: #666666;
  opacity: 0.5;
}

.form-control-rounded {
  border-radius: 16px;
}

.help-block {
  color: #949494;
  vertical-align: sub;
}

.raiting-box {
  display: flex;
}

.help-block.error-block {
  display: none;
}
.has-error .help-block.error-block.basic-block {
  display: block;
}

.input-group-addon-danger {
  background: #e85656;
  color: #ffffff;
  border-color: #e85656;
}

.input-group-addon-warning {
  background: #dfb81c;
  color: #ffffff;
  border-color: #dfb81c;
}

.input-group-addon-success {
  background: #90b900;
  color: #ffffff;
  border-color: #90b900;
}

.input-group-addon-primary {
  background: #209e91;
  color: #ffffff;
  border-color: #209e91;
}

.checkbox-demo-row {
  margin-bottom: 12px;
}

.dropdown-menu {
  border-radius: 5px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: #666666;
}

.bootstrap-select.btn-group button.btn.btn-default {
  background: transparent;
  color: #666666;
}
.bootstrap-select.btn-group button.btn.btn-default:hover {
  background: #ffffff;
  box-shadow: none;
  outline: 0 !important;
}
.bootstrap-select.btn-group button.btn.btn-default:active {
  background: #ffffff;
  box-shadow: none;
}
.bootstrap-select.btn-group.open > .btn.btn-default.dropdown-toggle {
  background: #ffffff;
  box-shadow: none;
  border-color: #d6d6d6;
}
.bootstrap-select.btn-group.open > .btn {
  border-radius: 5px 5px 0 0;
}
.bootstrap-select.btn-group.open .dropdown-menu.open {
  border: 1px solid gainsboro;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
.bootstrap-select.btn-group.with-search.open .btn-default + .dropdown-menu .bs-searchbox .form-control {
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
}
.bootstrap-select.btn-group.with-search.open .btn-default + .dropdown-menu .no-results {
  color: #7d7d7d;
}
.bootstrap-select.btn-group .notify {
  color: #7d7d7d;
}

.has-success {
  position: relative;
}
.has-success .control-label {
  color: #666666;
}
.has-success .form-control {
  border: 1px solid #a6c733;
}
.has-success .form-control:focus {
  box-shadow: none;
  border-color: #90b900;
}
.has-success label.custom-checkbox {
  color: #a6c733;
}
.has-success label.custom-checkbox > span:before {
  color: #a6c733;
}
.has-success label.custom-checkbox > span:hover:before {
  border-color: #a6c733;
}
.has-success .form-control-feedback {
  color: #a6c733;
}
.has-success .input-group-addon {
  background-color: #a6c733;
  color: #ffffff;
}

.has-warning {
  position: relative;
}
.has-warning .control-label {
  color: #666666;
}
.has-warning .form-control {
  border: 1px solid #e5c649;
}
.has-warning .form-control:focus {
  box-shadow: none;
  border-color: #dfb81c;
}
.has-warning label.custom-checkbox {
  color: #e5c649;
}
.has-warning label.custom-checkbox > span:before {
  color: #e5c649;
}
.has-warning label.custom-checkbox > span:hover:before {
  border-color: #e5c649;
}
.has-warning .form-control-feedback {
  color: #e5c649;
}
.has-warning .input-group-addon {
  background-color: #e5c649;
  color: #ffffff;
}

.has-error {
  position: relative;
}
.has-error .control-label {
  color: #666666;
}
.has-error .form-control {
  border: 1px solid #ed7878;
}
.has-error .form-control:focus {
  box-shadow: none;
  border-color: #e85656;
}
.has-error label.custom-checkbox {
  color: #ed7878;
}
.has-error label.custom-checkbox > span:before {
  color: #ed7878;
}
.has-error label.custom-checkbox > span:hover:before {
  border-color: #ed7878;
}
.has-error .form-control-feedback {
  color: #ed7878;
}
.has-error .input-group-addon {
  background-color: #ed7878;
  color: #ffffff;
}

.bootstrap-tagsinput {
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  box-shadow: none;
  max-width: 100%;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
}
.bootstrap-tagsinput.form-control {
  display: block;
  width: 100%;
}
.bootstrap-tagsinput .tag {
  border-radius: 3px;
  font-weight: 400;
  font-size: 11px;
  padding: 4px 8px;
}
.bootstrap-tagsinput .tag [data-role=remove]:hover {
  box-shadow: none;
}
.bootstrap-tagsinput input {
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  line-height: 22px;
  font-size: 11px;
  min-width: 53px;
}
.bootstrap-tagsinput input::-webkit-input-placeholder {
  color: #666666;
  opacity: 0.8;
}
.bootstrap-tagsinput input:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  opacity: 0.8;
}
.bootstrap-tagsinput input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  opacity: 0.8;
}
.bootstrap-tagsinput input:-ms-input-placeholder {
  color: #666666;
  opacity: 0.8;
}

.progress {
  background: rgba(0, 0, 0, 0.07);
}

.progress-bar-primary {
  background-color: #209e91;
}

.progress-bar-success {
  background-color: #b1ce4d;
}

.progress-bar-warning {
  background-color: #dfb81c;
}

.progress-bar-danger {
  background-color: #e85656;
}

.has-success .input-group-addon {
  border: none;
}

.input-group > span.addon-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group > span.addon-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group {
  margin-left: 0;
}
.input-group-btn > .btn {
  line-height: 1.56;
}

.with-primary-addon:focus {
  border-color: #209e91;
}

.with-warning-addon:focus {
  border-color: #dfb81c;
}

.with-success-addon:focus {
  border-color: #90b900;
}

.with-danger-addon:focus {
  border-color: #e85656;
}

.sub-little-text {
  font-size: 12px;
}

.rating {
  font-size: 20px;
}

rating-inputs span {
  vertical-align: middle;
}

label.custom-checkbox {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}
label.custom-checkbox > input {
  height: 0;
  z-index: -100 !important;
  opacity: 0;
  position: absolute;
}
label.custom-checkbox > input:checked + span:before {
  content: "\f00c";
  font-weight: 300;
}
label.custom-checkbox > input:disabled + span {
  color: #dddddd;
  cursor: not-allowed;
}
label.custom-checkbox > input:disabled + span:before {
  border-color: #dddddd !important;
  cursor: not-allowed;
}
label.custom-checkbox > span {
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: 16px;
  font-weight: 300;
  cursor: pointer;
  padding-left: 22px;
  width: 100%;
}
label.custom-checkbox > span:before {
  cursor: pointer;
  font-family: fontAwesome;
  font-weight: 300;
  font-size: 12px;
  color: #666666;
  content: " ";
  background-color: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  height: 16px;
  line-height: 14px;
  min-width: 16px;
  margin-right: 6px;
  position: relative;
  top: 0;
  margin-left: -22px;
  float: left;
}
label.custom-checkbox > span:hover:before {
  border-color: #4db1a7;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  color: #878787 !important;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg);
    /* Firefox 16+*/
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1003;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fc9535+0,f4b164+100 */
  background: linear-gradient(45deg, #ffce8e, #fab055, #dbbf60);
}
#preloader .loader-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
#preloader .loader-wrapper .logo {
  width: 100%;
  text-align: center;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.spinner-container {
  display: inline-block;
  position: relative;
  top: 195px;
}
.spinner-container img {
  display: block;
}

@-webkit-keyframes animBefore {
  0% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }
  25% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }
  50% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }
  75% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }
  100% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}
@keyframes animBefore {
  0% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }
  25% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }
  50% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }
  75% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }
  100% {
    transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}
@-webkit-keyframes animAfter {
  0% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }
  25% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }
  50% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }
  75% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }
  100% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}
@keyframes animAfter {
  0% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }
  25% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }
  50% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }
  75% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }
  100% {
    transform: scale(1) translateY(200%);
    z-index: 1;
  }
}
#contentLoad {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: #e1e6ec;
}
#contentLoad #contentContainer {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding-left: 100px;
}
#contentLoad #contentContainer img {
  width: 165px;
  margin-bottom: 35px;
}
#contentLoad #contentContainer .loaded span {
  display: inline-block;
  margin-left: 5px;
}
#contentLoad #contentContainer p {
  color: #374f6b;
}
#contentLoad #contentContainer p span {
  color: #0063CF;
  margin-right: 5px;
  display: inline-block;
  font-weight: bold;
}
#contentLoad #contentContainer > div {
  display: inline-block;
}

@media all and (max-width: 768px) {
  .spinner-container img {
    width: 100%;
  }

  #contentContainer {
    padding-left: 52px !important;
  }
  #contentContainer p {
    max-width: 265px;
  }
}
.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
}

button {
  cursor: pointer;
  border: 0;
  line-height: 19px !important;
  position: relative;
  transition: 0.2s;
}

.btn {
  border-radius: 5px;
  transition: all 0.1s ease;
  padding: 0.344rem 1rem;
  font-size: 0.9rem;
}

.btn-clean {
  border: 0;
  background: 0;
}

.button-primary {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 48px;
  color: white;
  background-color: #0063CF;
  border: solid 1px #0063CF;
  border-radius: 4px;
}
.button-primary:hover {
  color: white;
  background-color: #0063CF;
}
.button-primary.inverse {
  color: #0063CF;
  background-color: white;
}
.button-primary.inverse:hover {
  color: #0063CF;
  background-color: white;
}
.button-primary.m-progress {
  border-color: #ECEFF1 !important;
  background-color: #ECEFF1 !important;
}
.button-primary.disabled {
  cursor: default !important;
  border-color: #ECEFF1 !important;
  background-color: #ECEFF1 !important;
  color: #90A4AE !important;
}

.without-border {
  border: none;
  outline: none;
}
.without-border:disabled {
  color: #90A4AE;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.justify-content-end {
  justify-content: end;
}

.bold {
  font-weight: bold !important;
}

.no-border {
  border: 0;
}

.darkblue-font {
  color: #1B365D;
}

.blue-font {
  color: #0063CF;
}

.light-blue-font {
  color: #FF9E1B;
}

.light-blue-font {
  color: #5B7F95;
}

.white-font {
  color: #fff;
}

.black-font {
  color: #000;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 22px;
}

.fs22 {
  font-size: 22px;
}

.fs23 {
  font-size: 23px;
}

.fs24 {
  font-size: 24px;
}

.fs25 {
  font-size: 25px;
}

.fs26 {
  font-size: 26px;
}

.fs27 {
  font-size: 27px;
}

.fs28 {
  font-size: 28px;
}

.no-bg {
  background: 0;
}

.orange-gradient-bg {
  background: #fd7d00;
  background: linear-gradient(to right, #fd7d00 0%, #228BF0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fd7d00", endColorstr="#228BF0", GradientType=0);
}

.blue-gradient-bg {
  background: #426687;
  background: -moz-linear-gradient(left, #426687 0%, #66839e 100%);
  background: -webkit-linear-gradient(left, #426687 0%, #66839e 100%);
  background: linear-gradient(to right, #426687 0%, #66839e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#426687", endColorstr="#66839e", GradientType=0);
}

.darkblue-gradient-bg {
  background: #0063CF !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: RobotoBold;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: none;
  color: #FFFFFF !important;
  padding: 7px 15px;
}

.darkblue-gradient-bg:hover {
  background-color: #003e83 !important;
  color: #FFFFFF !important;
}

.darkblue-gradient-bg2 {
  background: #69b1ff !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: RobotoBold;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: none;
  color: #FFF;
  padding: 7px 15px;
}

.darkblue-gradient-bg2:hover {
  background-color: #0063CF !important;
  color: #FFF;
}

.flex-behaviour {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.display-flex {
  display: flex;
}

.relative-pos {
  position: relative;
}

.gotham-bold {
  font-family: RobotoBold !important;
}

.gotham-medium {
  font-family: RobotoMedium !important;
}

.hurme {
  font-family: Roboto !important;
}

.hide {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.error-iclinic {
  background: #FFEBEE;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.warning-iclinic {
  background: #FFF8E1;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.info-iclinic {
  background: #eaf3f5;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.success-iclinic {
  background: #E8F5E9;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.444444px;
}

.btn-white-blue {
  background-image: linear-gradient(to bottom, #fff, #fafafa);
  border-color: #e6e6e6 !important;
  box-shadow: inset 0 1px 0 0 #fff, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
  color: #2c97d1 !important;
  background-color: #fff !important;
}